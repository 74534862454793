.ReportVideoModal {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__formGroup {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 10px;
  }

  .SaveButtonsWrapper {
    border-top: none;
  }
}
