.ProfileSegment {
  &__pictureWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
  }

  &__name {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }

  &__loginWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3.2em;
    margin-bottom: 4.7em;
  }

  &__link {
    color: black;
  }

  &__stats {
    margin-top: 6px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    text-transform: uppercase;

    &__highlight {
      color: red;
    }
  }
}

.ProfileSegment--dark {
  color: white;

  .ProfileSegment__name {
    color: white;
  }

  .ProfileSegment__stats {
    color: white;
  }

  .ProfileSegment__stats__highlight {
    color: red;
  }
}
