.FeatureToggle {
  margin-bottom: 10px;

  &__title {
    position: relative;

    &:nth-child(1) {
      font-weight: bold;
      position: relative;
    }

    &:nth-child(1)::before {
      display: inline-block;
      content: '';
      -webkit-border-radius: 0.375rem;
      border-radius: 0.375rem;
      height: 0.75rem;
      width: 0.75rem;
      margin-right: 24px;
      background: #de1919;
    }

    .FeatureToggle__arrow {
      position: absolute;
      right: 10px;
      color: #de1919;
    }

    &:nth-child(2) > * {
      font-size: 20px;
      position: absolute;
      right: 10px;
    }
  }
  &__content {
    font-size: 15px;
    margin-left: 36px;
  }
}
