.MatchDetailSituationsContent {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
  align-items: flex-start;

  @media (max-width: 1025px){
    flex-wrap: wrap;
  }
}
