.NotesByMatchesTab {
  width: 100%;

  @media (min-width: 1025px) {
    height: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: auto;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 90px;
    padding-top: 5px;

    @media (min-width: 1025px) {
      padding-bottom: 18px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__card {
    margin: 10px 10px 0 10px;
    padding: 0 1em;
    background: white;
    color: black;
    height: fit-content;
    border-radius: 10px;
    width: 600px;
    max-width: calc(100% - 20px - 2em);
  }

  &__cardTitle {
    font-weight: bold;
    padding: 1em 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    span {
      padding-right: 1em;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__notes {
    // padding: 1em 0.5em;
  }

  &__note {
    border-top: 1px solid #c5c5c5;
    padding: 1em 0.5em;
  }
}

.NotesByMatchesTab--dark {
  .NotesByMatchesTab__card {
    background: #212121;
    color: white;
  }
}
