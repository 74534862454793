.SidePanel {
  width: 0;
  height: 100vh;
  position: absolute;
  background: #fff;
  left: 0;
  top: 0;
  transition: width 0.15s ease-in-out;
  overflow: auto;
  //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding-top: env(safe-area-inset-top); //iOS bezel fix

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  @media (min-width: 1025px) {
    width: 260px;
    position: relative;
    left: auto;
    top: auto;
    transition: none;
  }

  &__content {
    height: calc(100% - 143px);
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 1025px) {
      height: calc(100% - 76px);
    }
  }
}

.SidePanel--visible {
  @media (max-width: 1025px) {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 100px 100px;
  }
  width: 85%;
}

.SidePanel--dark {
  background: #212121;
}
