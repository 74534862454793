.BottomMenuItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 26px);
  padding: 13px 0;
  color: black;

  &__link {
    width: 20%;
    height: 70px;
    font-size: 12px;
  }
}

.BottomMenuItem--active {
  font-weight: bold;
}

.BottomMenuItem--dark {
  background-color: #191919;
  color: white;
}
