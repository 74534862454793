.LoginPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;

  &__container {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 40px 0;
  }

  &__textField {
    width: 100%;
    margin: 5px 0 !important;
  }

  &__errorMessage {
    text-align: center;
    margin-top: 1em;
    color: #de1919;
  }

  &__button {
    width: 100%;
    height: 50px;
    margin: 30px 25px 0;
    background-color: #de1919;
    color: white;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &--disabled {
      background-color: #c5c5c5;
      color: black;
      cursor: not-allowed;
    }
  }

  &__spinner {
    display: flex;
    align-self: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid white;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: spin 0.6s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  }

  &__placeholder {
    height: 20px;
  }

  a {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    height: fit-content;
    font-size: 16px;
  }

  &__mobile-link {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    height: fit-content;
    font-size: 16px;
    color: #777;
    display: inline-block;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
