.AccountingPage {
  width: 100%;
  background-color: #f9f9f9;
  height: 100%;
  flex-direction: column;
  display: flex;
  padding: 0;
  overflow: auto;

  &__content {
    display: flex;
    height: 100%;
    position: relative;
    flex-direction: column;
    overflow: auto;

    @media (min-width: 1025px) {
      padding-top: 0px;
      padding-bottom: 25px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 1025px) {
      flex-direction: row;
      margin: 0 50px;
    }
  }
}

.AccountingPage--dark {
  background-color: #191919;
  color: white;
}
