.CompetitionsPage {
  width: 100%;
  background-color: #f9f9f9;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: auto;

  &__scrollWrapper {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__contentWrapper {
    width: 100%;
    position: absolute;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 5px 0;

    @media (min-width: 1025px) {
      flex-direction: row;
    }
  }

  &__container {
    width: calc(100% - 80px);
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    margin: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

    @media (min-width: 1025px) {
      margin: 10px 5px 0px 5px;
      width: calc(100% / 2 - 100px);
    }
  }

  &__headerText {
    font-weight: 700 !important;
    font-size: 0.92rem !important;
  }

  &__modalHeader {
    display: flex;
    justify-content: center;
    font-weight: bold;
    border-bottom: 1px solid rgba(215, 215, 215, 1);
    padding-bottom: 15px;
    margin-bottom: 30px;
  }
}

.CompetitionsPage--dark {
  background-color: #191919;
  color: white;

  .CompetitionsPage__container {
    background-color: #212121;
  }
}
