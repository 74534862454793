.MatchCardItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 7px 0;

  &__iconWrapper {
    margin-right: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
