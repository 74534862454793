@import "src/mixins";

.MatchDetailResyncClipsModal {

  &__confirmation-modal {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;

    border-bottom: 1px solid rgba(215, 215, 215, 1);
    margin-bottom: 15px;
    padding-bottom: 30px;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 0;

    @media (max-width: 401px) {
      gap: 10px;
    }
  }


  &__timestampsWrapperModal {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
  }
}

.ResynchronizationModal {
  display: flex;
  @include mac-like-scrollbar;
  flex-direction: column;
  max-height: 90%;

  &__timestampsWrapperModal {
    overflow-y: auto;
    @include mac-like-scrollbar;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    max-height: 350px;
    margin: 10px 0;
  }
}