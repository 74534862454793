.MatchTable {
  margin-right: 48px;
  padding: 12px 24px 48px 24px;
  border-radius: 10px;
  background-color: white;
  width: calc(100% - 70px);

  &__cell {
    padding: 8px 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 8px;
  }

  &__headerText {
    margin-left: 4px;
    font-weight: bold;
    width: max-content;
  }

  table {
    border-collapse: collapse;
  }

  td,
  th {
    font-size: 14px;
  }

  td {
    height: 24px;
  }

  &__row {
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: rgba(#707070, 0.2);
    }
  }
}

.MatchTable--dark {
  background-color: #212121;
}
