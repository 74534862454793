.ClipController {
  width: calc(100% - 20px);
  margin: 0 10px;

  &__container {
    padding: 30px;
    background-color: white;
    border-radius: 10px;

    @media (min-width: 1025px) {
      margin-bottom: 1.3em;
      width: calc(100% - 80px);
    }

    @media (max-width: 600px) {
      padding-inline: 10px;
    }

    &--dark {
      background-color: #212121;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h4 {
      margin: 0;
    }
  }

  &__moreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 0.5em;
  }

  &__moreTooltip {
    padding: 10px;
    background-color: white;
    top: 10px;
    right: -1px;
    display: none;
    z-index: 9;
    border-radius: 10px;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

    &--visible {
      display: block;
    }
  }

  &__moreTooltip--dark {
    background-color: #191919;
    color: white;
  }

  &__timeInputDescription {
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    margin-top: 0.5em;
    margin-bottom: 0.2em;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &--dark {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  &__tooltipButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 5px;
    cursor: pointer;

    &--green {
      background-color: #4caf50;
      color: white;
      border-radius: 1em;
    }

    &--disable {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  &__tooltipButtonLabel {
    margin-left: 10px;
  }

  &__timeStampsControllers {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    max-width: 410px;
  }

  &__switchWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.ClipController__tooltipButton--disable {
  color: #c3c3c3;
}

.ClipController__tooltipButton--opaque {
  opacity: 0.4;
}
