.ContentLoader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;

  &__spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #777;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: spin 0.6s cubic-bezier(0.37, 0, 0.63, 1) infinite;
    z-index: 2000;
  }
}

.ContentLoader--dark {
  .ContentLoader__spinner {
    border: 5px solid white;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
