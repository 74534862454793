.TipBar {
  margin-top: 35px;
  cursor: pointer;

  p {
    height: 40px;
    font-size: 12px;
    text-align: center;
    margin: 15px 35px;
  }

  p.boldText {
    height: 35px;
    margin: 15px 10px;
    font-weight: bold;
  }

  &__dots {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0 20px;
  }

  &__dot {
    width: 10px;
    height: 10px;
    background: #ececec;
    border-radius: 50%;
    margin: 0 5px;

    &--active {
      background: #000000;
    }
  }
}

.TipBar--dark {
  color: white;

  .TipBar__dot {
    background: #707070;

    &--active {
      background: #fff;
    }
  }
}
