.ClipCard {
  width: calc(100vw - 40px);
  max-width: 360px;
  height: 270px;
  flex: 0 0 auto;
  margin: 12px 6px;
  border-radius: 10px;
  //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background: white;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 470px;
  }

  &__taller {
    height: 280px;
  }

  &__first {
    margin-left: 12px;

    @media (min-width: 768px) {
      margin-left: 32px;
    }
  }

  &__last {
    margin-right: 12px;

    @media (min-width: 768px) {
      margin-right: 32px;
    }
  }

  &__thumbnail {
    width: 100%;
    height: 164px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &__premium {
      background-color: rgba(0, 0, 0, 0.5);
      position: relative;
      top: -167px;
      margin-bottom: -167px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__iconWrapper {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
      position: relative;
      font-size: 2.5em !important;
    }
    &__content {
      margin-top: 8px;
      font-size: 1em;
      font-weight: 600;
      width: 80%;
      text-align: center;
    }
  }

  &__thumbnailBorder {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid lightgrey;
  }

  &__info {
    margin: 12px 18px 18px;
  }

  &__label {
    margin: 3px 0;
    text-overflow: ellipsis;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__labelText {
    margin-left: 8px;
    font-size: 14px;
    color: #212121;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 430px;
  }
}

.ClipCard--dark {
  background-color: #212121;

  .ClipCard__labelText {
    color: white;
  }
}
