.Button {
  position: relative;
  height: 35px;
  border-radius: 35px;
  padding: 0 20px;
  line-height: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  &__spinner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid white;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: spin 0.6s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  }

  &__primary {
    @extend .Button;
    min-width: 70px;
    background-color: #de1919;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.5em 0 0;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s ease-in-out;

    a {
      color: white;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        opacity: 0.7;
      }
    }

    &--dark {
      @extend .Button__primary;
    }

    &--disabled {
      @extend .Button__primary;
      background-color: #ececec;
      color: #c3c3c3;
      border: none;
      cursor: default;
      &:hover {
        opacity: 1;
      }
    }

    &--dark--disabled {
      @extend .Button__primary--dark;
      @extend .Button__primary--disabled;
      background-color: #444444;
    }
  }

  &__secondary {
    @extend .Button;
    min-width: 70px;
    height: 31px;
    background-color: transparent;
    border: 2px solid #de1919;
    font-weight: 700;
    color: #de1919;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1em;
    user-select: none;
    cursor: pointer;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out;

    a {
      color: #de1919;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        opacity: 0.7;
      }
    }

    &--disabled {
      @extend .Button__secondary;
      color: #777777;
      border: 2px solid #777777;
      cursor: default;

      &:hover {
        opacity: 1;
      }
    }

    &--dark {
      @extend .Button__secondary;
    }

    &--dark--disabled {
      @extend .Button__secondary--dark;
      @extend .Button__secondary--disabled;
    }
  }

  &__basic {
    @extend .Button;
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ececec;
    user-select: none;

    &--disabled {
      @extend .Button__basic;
      color: #c3c3c3;
    }

    &--dark {
      @extend .Button__basic;
      background-color: #616161;
    }

    &--dark--disabled {
      @extend .Button__basic--dark;
      @extend .Button__basic--disabled;
    }

    .left-icon {
      display: inline-block;
      margin-right: 10px;
    }

    .right-icon {
      display: inline-block;
      margin-left: 10px;
    }
  }

  &--adaptable {
    @extend .Button;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  &--stretch {
    @extend .Button;
    margin: 0;
    width: calc(100% - 40px);
  }
}

.Button--disable {
  background-color: #474747;
  color: #858585;
}

.Button--dark--disable {
  background-color: #474747;
  color: #858585;
}

.Button__basic--dark {
  // background-color: #858585;
  // background-color: #616161;

  &--disabled {
    background-color: #474747;
  }
}
