.MenuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 25px;
  text-decoration: none;
  user-select: none;
  color: #777;
  transition: color 0.2s ease-in-out;

  &__iconWrapper {
    width: 25px;
    height: 25px;
    display: flex;
    align-content: center;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }

  p {
    margin-left: 20px;
    text-decoration: none;
    font-size: 13px;
  }

  &:hover {
    color: black;
  }

  &--active {
    font-weight: bold;
    color: black;

    &.MenuItem__disabled {
      cursor: default;

      &:hover {
        color: black;
      }
    }
  }

  &__disabled {
    cursor: default;

    &:hover {
      color: #777;
    }
  }
}

.MenuItem--dark {
  &:hover {
    color: white;
  }

  &.MenuItem__disabled {
    cursor: default;

    &:hover {
      color: #777;
    }
  }

  &.MenuItem--active {
    color: white;

    &.MenuItem__disabled {
      cursor: default;

      &:hover {
        color: white;
      }
    }
  }
}
