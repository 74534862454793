.Title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;

  @media (min-width: 1025px) {
    margin: 0 24px 0 24px;
    border-bottom: 1px solid #c5c5c5;
    justify-content: space-between;
  }

  &__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }

  &__tab {
    padding: 10px 20px;
    font-size: 13px;
    color: black;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    @media (min-width: 1025px) {
      cursor: pointer;
      padding: 20px 15px 40px;
      font-size: 15px;
      margin: 0 0 -13px;
      height: 15px;
      border-bottom: 3px solid transparent;
    }

    &--active {
      color: #de1919;
      border-bottom: 3px solid #de1919;
    }
  }

  &__filterButton {
    padding: 10px 25px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: #ebebeb;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    user-select: none;

    &__premium {
      user-select: none;
      position: relative;
      left: -85px;
      top: 10px;
      margin-top: -27px;

      &__iconWrapper {
        color: red;
      }
      &__icon {
        position: relative;
        top: 5px;
        margin-left: 5px;
      }
      &__content {
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }

  &__filterText {
    margin-right: 12px;
    font-weight: bold;
    font-size: 14px;
  }

  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      margin: 0 0 0 20px;
      font-size: 24px;
    }
  }

  &__backButton {
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
  }

  &__arrowItem {
    display: flex;
    align-items: center;
  }

  &__actionButton {
    margin-left: 10px;
    padding: 5px;
    cursor: pointer;
    user-select: none;
  }

  &__filterBadge {
    background-color: #de1919;
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    line-height: 18px;
    font-size: 10px;
    color: white;
    text-align: center;
    font-weight: bold;
    position: absolute;
    right: -4px;
    top: -4px;
  }

  &__buttonWithBoldText {
    font-weight: bold;
  }
}

.Title--dark {
  color: white;

  @media (min-width: 1025px) {
    border-bottom: 1px solid #777777;
  }

  .Title__tab {
    color: white;
  }

  .Title__tab--active {
    color: #de1919;
  }

  .Title__filterButton {
    background-color: #191919;
  }
}
