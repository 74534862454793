.UnavailableVideoNotification {
  @media (min-width: 1025px) {
    border-radius: 10px;
  }

  width: 100%;
  padding-top: 56.25%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #e0e0e0;
  position: relative;

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__alertIcon {
    max-width: 130px;
    max-height: 100%;
    width: 15%;
  }

  img {
    width: 100%;
  }

  h4 {
    position: relative;
    margin: 0;
  }
  .messageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 1rem 1.5rem 0 1.5rem;

    @media (max-width: 520px) {
        font-size: 12px;
        gap: 0rem;
    }

    .timeOverlapButton {
        @media (max-width: 520px) {
            font-size: 12px;
        }
    }
  }

  p {
    opacity: 0.6;

    @media (max-width: 520px) {
        margin: 0.5rem 0;
    }
  }
}

.UnavailableVideoNotification--dark {
  background-color: #212121;
}
