.Alert__buttons {
  div {
    display: inline-block;
  }
}

.AlertModal__text {
  margin-bottom: 20px;
}

.AlertModal {
  max-width: 33%;

  @media (max-width: 512px) {
    max-width: 70%;
  }
}
