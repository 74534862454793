@import 'src/mixins';
@import 'src/variables';

.DigiRecorderContent {
  margin: auto;
  width: MIN(500px, calc(100% - 20px));
  height: 100%;
  overflow: auto;

  &__container {
    background: #fff;
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
    @media screen and (max-width: $small_screen) {
      margin-inline: 0;
      padding: 8px;
    }
  }

  @include mac-like-scrollbar;
}

.DigiRecorderContent--dark {
  color: #fff;

  .DigiRecorderContent__container {
    background-color: #212121;
  }
}
