@import '../../mixins';

.MatchDetailNotesContent {
  // display: flex;
  // justify-content: center;

  @media (min-width: 1025px) {
    min-height: 100%;
  }

  &__container {
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 100%;

    @include shrink-padding-on-mobile;
    @media (max-width: 600px) {
      width: auto;
    }
  }

  &__centeredContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__containerHeading {
    font-weight: 700;
    font-size: 1.2em;
    margin-bottom: 1.5em;
  }

  &__addButton {
    font-size: 12px;
    text-align: center;
    color: #de1919;
    background-color: transparent;
    border: 2px solid #de1919;
    border-radius: 100px;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-weight: bold;
    user-select: none;
    cursor: pointer;

    @media (min-width: 1025px) {
      font-size: 14px;
    }

    &--dynamic-width {
      padding: 0 20px;
      width: max-content;
    }
  }
}

.MatchDetailNotesContent--dark {
  .MatchDetailNotesContent__container {
    background-color: #212121;
  }
}
