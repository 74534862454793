.ThumbsUpDown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__thumbs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    flex-wrap: nowrap;
  }

  &__buttons {
    display: flex;
    gap: 1rem;
  }

  &__reaction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 6ch;
    border: 4px solid #9f9f9f42;
    padding: 8px 12px;
    border-radius: 999px;
    transition: border-color ease 200ms;

    &--active {
      border: 4px solid currentColor;
    }
  }

  &__totalRating {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    padding: 0;
    margin: 0;
  }
}
