.AccountingCard {
  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    height: fit-content;
    width: calc(100% - 25px);
    position: absolute;
    margin-left: 25px;
    padding-top: 20px;
    padding-bottom: 40px;

    @media (max-width: 1025px) {
      padding-bottom: 170px;
      margin-left: 12px;
    }

    @media (max-width: 726px) {
      width: 100%;
      margin-left: 0px;
      padding-top: 5px;
    }
  }
}
