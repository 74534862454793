.Modal {
  background-color: white;
  overflow: auto;
  padding: 20px;
  border-radius: 10px;
  margin: 20px;
  overscroll-behavior: none;
  max-width: 90vw;
  max-height: 90vh;

  &__overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);

    .modalHeader {
      display: flex;
      justify-content: center;
      font-weight: bold;
      border-bottom: 1px solid #d7d7d7;
      padding-bottom: 15px;
      margin-bottom: 10px;
    }

    .modalContent {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      div {
        margin-top: 20px;
      }
    }
  }
}

.Modal--dark {
  background-color: #191919;
  color: white;
}
