.GetPremiumButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px 2.5rem 25px 2.5rem;

  &__button {
    display: inline-block;
    background-color: #de1919;
    color: white;
    border-radius: 50px;
    font-size: 1rem;
    text-align: center;
    font-weight: 700;
    padding: 8px 12px;

    &:hover {
      opacity: 0.8;
      color: white;
    }
  }
}
