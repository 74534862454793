.MobileTitle {
  width: 100%;
  background-color: #ffffff;
  //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #c5c5c5;
  padding-top: env(safe-area-inset-top); //iOS bezel fix

  &__actionButtons {
    display: inline-flex;
    gap: 12px;
    margin-right: 12px;
  }

  &__actionButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__topSegment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;

    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(80vw - 34px);
    }
  }

  &__backButton {
    padding: 20px 0;
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__tab {
    padding: 0 6px 13px 0;
    text-align: center;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &--active {
      padding: 0 0 10px;
      border-bottom: 3px solid #c71616;
      color: #c71616;
    }
  }

  &__arrowItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__arrow {
    @media (max-width: 270px) {
      display: none;
    }
  }

  &__filterPanelButton {
    position: relative;
  }

  &__filterBadge {
    background-color: #de1919;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    line-height: 16px;
    font-size: 10px;
    color: white;
    text-align: center;
    font-weight: 600;
    position: absolute;
    right: -10px;
    top: -10px;
  }
}

.MobileTitle--dark {
  background-color: #191919;
  color: white;
  border-color: #707070;
}

.MobileTitle__tabs {
  overflow-x: auto;
}

.MobileTitle__tabs::-webkit-scrollbar {
  display: none;
}
