.TopSegment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 20px;
  user-select: none;

  &__iconWrapper {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
    cursor: pointer;
  }

  &__logoWrapper {
    position: relative;
    top: -1px;
  }

  &__placeHolder {
    width: 25px;
  }
}
