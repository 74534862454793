.GeneralNotepadTab {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  &__content {
    display: flex;
    position: absolute;
    padding: 12px;
    width: calc(100% - 24px);

    @media (min-width: 1025px) {
      padding-top: 18px;
      padding-bottom: 18px;
      margin: 0 24px;
      width: calc(100% - 36px - 48px);
      flex-direction: row;
      justify-content: center;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__notepad {
    width: 100%;

    @media (min-width: 1025px) {
      // width: 60%;
    }
  }
}
