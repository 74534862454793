.VideoListPage {
  width: 100%;
  background-color: #f9f9f9;
  height: 100%;
  padding: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &__swimLinesWrapper {
    height: 100%;
    overflow: auto;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__swimLines {
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: auto;
    overflow-x: hidden;
    width: 100%;

    @media (min-width: 1025px) {
      padding-top: 18px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.VideoListPage--dark {
  background-color: #191919;
  color: white;
}
