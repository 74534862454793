.FilterPanel {
  width: 0;
  height: calc(100vh - env(safe-area-inset-bottom)); //iOS bezel fix;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  transition: width 0.15s ease-in-out;
  background: white;
  //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding-bottom: env(safe-area-inset-bottom); //iOS bezel fix;
  overflow: hidden;

  &__headingWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    margin: 0 1em;
    border-bottom: 1px solid #c5c5c5;
  }

  &__heading {
    padding-top: env(safe-area-inset-top);
    font-size: 1.1em;
    font-weight: bolder;

    &__premium {
      margin-top: 0.5em;
      display: flex;
      align-items: center;
      opacity: 0.3;
      font-size: 0.6em;
      cursor: pointer;

      &__icon {
        margin-right: 0.3em;
        font-size: 1.5em !important;
      }
    }
  }

  .checkboxWrapper {
    display: flex;
    flex-direction: column;
  }

  &__close {
    cursor: pointer;
    padding: 0.3em;
  }

  &__content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    &__premium {
      opacity: 0.5;
    }
  }

  &__segment {
    padding: 1em;
    margin: 0 1em;
    border-bottom: 1px solid #c5c5c5;
    min-height: 300px;

    &:last-child {
      border: none;
    }
  }

  &__segmentHeading {
    font-weight: bolder;
    text-transform: uppercase;
  }

  &__categoryTypeHeading {
    margin-top: 1em;
    font-weight: bolder;
  }
}

.FilterPanel--visible {
  width: 400px;

  @media (max-width: 1025px) {
    width: 85%;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 100px 100px;
  }
}

.FilterPanel--dark {
  background: #212121;
  color: white;

  .FilterPanel__heading__premium .FilterPanel__heading__premium {
    opacity: 0.54;
  }
}
