.Divider {
  width: 90%;
  height: 0;
  border-top: solid 1px #c5c5c5;
  margin: 20px 5%;
}

.Divider--dark {
  border-color: #707070;
}
