.ProfilePicture {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;

  &__wrapper {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    border-radius: 50%;
    border: none; // 5px solid red;
    border-right-color: #ececec;
    border-bottom-color: transparent;
  }

  &__picture {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
}

.ProfilePicture--dark {
  .ProfilePicture__wrapper {
    border-right-color: #212121;
  }
}
