.TimeStampController {
  width: fit-content;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-right: 10px;
  }

  &__head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    max-width: 125px;
    text-align: center;
  }

  &__label {
    display: flex;
    align-items: center;
    height: 35px;
    overflow: hidden;
    margin-left: 10px;
    font-size: 14px;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 150px;
    min-width: 125px;
    user-select: none;
  }

  &__controlsButton {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #ececec;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    user-select: none;

    &__disabled {
      opacity: 0.3;
      cursor: default;
    }
  }
}

.TimeStampController--dark {
  .TimeStampController__controlsButton {
    background-color: #616161;
  }
}
