.ClipCardLoader {
  &__thumbnail {
    background-color: #e3e3e3;
  }

  &__labelText {
    background-color: #e3e3e3;
    color: #e3e3e3;
    border-radius: 0.3em;
  }
}

.ClipCardLoader--dark {
  background-color: #212121;
}
