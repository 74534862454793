.MatchCard {
  border-radius: 10px;
  margin: 5px 10px;
  padding: 10px;
  width: calc(100vw - 40px);
  //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  background-color: white;

  &__itemWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.MatchCard--dark {
  background-color: #212121;
}
