@import 'src/variables';

.StatisticsCard {
  background: white;
  display: inline-block;
  padding: 20px;
  font-size: 15px;
  position: relative;
  color: black;
  border-radius: 10px;
  margin: 10px 10px 0px 10px;

  h3 {
    margin: 0px;
    font-size: 15px;
    width: 100%;
  }

  table tr td:nth-child(1) {
    /* width: 110px; */
    width: 33%;
  }

  table tr td:nth-child(2) {
    // width: 150px;
    width: 33%;
    text-align: right;
  }

  table tr td:nth-child(3) {
    width: 33%;
    text-align: right;
  }

  .card-sum {
    font-weight: bold;
  }

  table {
    border-spacing: 0 7px;
    // margin: 0px auto;
    width: 100%;
  }

  .card-premium {
    color: rgba(220, 0, 0, 1);
  }

  .card__premium__icon {
    font-size: 15px;
    margin-right: 4px;
  }

  .card-explanation {
    position: absolute;
    top: 23px;
    right: 20px;
    color: #919191;
    font-size: 11px;
    display: inline-block;
    width: 100%;
    text-align: right;
  }

  @media (max-width: 408px) {
    h3 {
      font-size: 13px;
    }

    table tr td:nth-child(1) {
      width: 54px;
      font-size: 10px;
    }

    .card-premium {
      font-size: 10px;
    }

    .card__premium__icon {
      font-size: 9px;
      margin-right: 1px;
    }

    .card-explanation {
      position: absolute;
      top: 23px;
      right: 20px;
      font-size: 10px;
    }

    table tr td:nth-child(2) {
      font-size: 10px;
    }

    table tr td:nth-child(3) {
      font-size: 10px;
    }
  }

  /* card widths */
  width: calc(25% - 60px);

  @media (max-width: $small_screen) {
    width: calc(100% - 60px);
    border-radius: 7px;
  }

  @media (max-width: $medium_screen) and (min-width: $small_screen) {
    width: calc(50% - 60px);

    // .card-premium {
    //   font-size: 10px;
    // }

    // .card__premium__icon {
    //   font-size: 9px;
    //   margin-right: 1px;
    // }
  }

  @media (max-width: $big_screen) and (min-width: $medium_screen) {
    width: calc(33% - 60px);
  }

  @keyframes barLoad {
    0% {
      width: 0px;
    }
  }

  .StatisticsBar {
    animation: 1s ease-out 0s 1 barLoad;
  }
}

.StatisticsBar {
  background: rgba(220, 0, 0, 1);
  height: 10px;
  width: 30px;
}

.StatisticsCard--dark {
  background: #212121;
  color: white;
}
