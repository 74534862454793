.SettingsPage {
  width: 100%;
  background-color: #f9f9f9;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &__spacer {
    height: 110px;
  }

  &__scrollWrapper {
    display: flex;
    overflow: auto;
    position: relative;
    width: 100%;
    height: 100%;

    @media (min-width: 1025px) {
      padding-top: 18px;
      padding-bottom: 18px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__content {
    position: absolute;
    margin: 10px;
    width: calc(100% - 20px);
    min-height: min-content;
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    flex-direction: column;
  }

  &__container {
    width: calc(100% - 40px);
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 5px 0;

    @media (min-width: 1025px) {
      width: 400px;
    }
  }

  &__containerHeading {
    font-weight: 700;
  }

  &__premium {
    width: 100%;
    margin-top: -1em;
    display: flex;
    align-items: center;
    opacity: 0.3;
    font-size: 0.6em;
    cursor: pointer;

    &__icon {
      margin-right: 0.3em;
      font-size: 1.5em !important;
    }
  }

  &__myAccountLink {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: black;

    span {
      margin: 1em 0;
    }
  }
}

.SettingsPage--dark {
  background-color: #191919;
  color: white;

  .SettingsPage__container {
    background-color: #212121;
  }

  .SettingsPage__premium {
    opacity: 0.54;
  }

  .SettingsPage__myAccountLink {
    color: white;
  }
}
