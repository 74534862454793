.DesktopLayout {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;

  &__middleContent {
    width: 100%;
    height: 100vh;
  }
}

.DesktopLayout--dark {
  background-color: #191919;
}
