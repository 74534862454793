.ClipCategoriesContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;

  &__saveButton {
    margin: auto;
  }
}
