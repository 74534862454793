.TimeInput {
  &__timeInput {
    width: 35px;
  }

  &__activeTimeInputDivider {
    font-size: 1.1em;
    margin: 3px 10px 0 5px;
    display: inline-block;

    &__withLabels {
      margin-top: 18px;
    }
  }

  &__passiveTimeInput {
    font-size: 1.1em;
    line-height: 1.1em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__passiveTimeInputNumber {
  }

  &__passiveTimeInputDivider {
    margin: 0 0.3em;
  }
}
