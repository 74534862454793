@import 'src/mixins';

$input-button-width: 170px;

.MatchDetailVideoContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    min-height: 100%;
  }

  &__column {
    flex-direction: row;
    align-items: center;
    display: flex;
    flex-grow: 1;
    padding: 0 12px;
  }

  &__container {
    width: calc(100% - 80px);
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 10px;
    position: relative;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

    @media (min-width: 1025px) {
      width: 550px;
    }

    /* Popper */
    &__moreButton {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      // position: relative;
      padding-bottom: 0.5em;
    }

    &__moreTooltip {
      padding: 10px;
      background-color: white;
      top: 20px;
      right: -1px;
      display: none;
      z-index: 9;
      border-radius: 10px;
      margin-top: 20px;

      &--visible {
        display: block;
      }
    }

    &__moreTooltip--dark {
      background-color: #191919;
      color: white;
    }

    &__tooltipButton {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 5px;
      cursor: pointer;
    }

    @include shrink-padding-on-mobile;
  }

  &__containerHeading {
    font-weight: 700;
    font-size: 1.2em;
  }

  &__fileInputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }

  &__fileInputDescription {
    opacity: 0.54;
    font-size: 0.75rem;
    margin-top: 0.5em;
  }

  &__playVideoWrapperModal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;

    .VideoPlayer {
      max-width: 90vw !important;
      max-height: calc(0.5625 * 90vw) !important;
      height: calc(85vh - 80px) !important;

      video {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  &__playVideoSyncTimeWrapperModal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 1em 0;

    .VideoPlayer {
      max-width: 90vw !important;
      width: 100% !important;
      max-height: calc(0.5625 * 90vw) !important;
      height: calc(85vh - 150px) !important;

      @media (max-width: 600px) {
        width: calc(100% + 40px) !important;
      }

      video {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  &__playVideoContainer {
    width: calc(100% - 40px);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    max-width: 450px;

    @media (min-width: 1025px) {
      width: 500px;
    }
  }

  &__playVideoModalHeader {
    display: flex;
    justify-content: center;
    font-weight: bold;
  }

  &__fileInputWrapperModal {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
  }

  &__fileInputWrapperModalUploadFromDevice {
    width: 100%;
    @media (max-width: $small_screen) {
      > .MatchDetailVideoContent__fileInputWrapper {
        flex-direction: column;
        align-items: start;
      }
    }
  }

  &__fileInputModalHeader {
    display: flex;
    justify-content: center;
    font-weight: bold;
    border-bottom: 1px solid rgba(215, 215, 215, 1);
    padding-bottom: 15px;
    margin-bottom: 30px;
  }

  &__fileInputModalText {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding: 15px;

    &--underlined {
      border-bottom: 1px solid rgba(215, 215, 215, 1);
      margin-bottom: 15px;
      padding-bottom: 30px;
    }
  }

  &__modalBtn {
    color: rgb(222, 25, 25);
    font-weight: 500;
    cursor: pointer;
  }

  &__linkButtonModal {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;
  }

  &__linkPlusButton {
    color: rgba(0, 0, 0, 0.12);
    width: 125px;
    border: 1px dashed rgba(0, 0, 0, 0.12);
    height: 35px;
    margin-bottom: 10px !important;
    transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    display: flex;
    min-width: 180px;
  }

  &__linkPlusButton--dark {
    color: rgba(0, 0, 0, 0.12);
    width: 125px;
    border: 1px dashed white;
    height: 35px;
    margin-bottom: 10px !important;
    transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    display: flex;
    min-width: 180px;
  }

  &__linkPlusButtonIcon {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }

  &__linkPlusButtonIcon--dark {
    fill: white;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }

  &__timeInput {
    width: 35px;
  }

  &__fileInput {
    display: none;
  }

  &__fileInputButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    font-size: 12px;
    text-align: center;
    color: white;
    background-color: #de1919;
    border: 2px solid #de1919;
    border-radius: 50px;
    font-weight: 700;
    width: $input-button-width;
    height: 19px;
    user-select: none;
    flex-shrink: 0;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    @include button-mobile-full-width;

    @media (min-width: 1025px) {
      font-size: 14px;
    }

    &__spinner {
      position: absolute;
      left: 5px;

      &__wrapper {
        display: flex;
        align-items: center;
      }

      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid white;
      border-bottom-color: transparent;
      border-left-color: transparent;
      animation: spin 0.6s cubic-bezier(0.37, 0, 0.63, 1) infinite;
      margin-right: 1em;
    }

    &--green {
      background-color: #4caf50;
      border: 2px solid #4caf50;

      &:hover {
        background-color: #388e3c;
      }
    }

    &--gray {
      background-color: rgb(133, 133, 133);
      border: 2px solid rgb(133, 133, 133);

      &:hover {
        opacity: 1;
        cursor: default;
      }
    }

    &--premium {
      background-color: rgb(133, 133, 133);
      border: 2px solid rgb(133, 133, 133);
      user-select: none;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__premium {
    width: 100%;
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    opacity: 0.3;
    font-size: 0.6em;
    cursor: pointer;

    &__icon {
      margin-right: 0.3em;
      font-size: 1.5em !important;
    }
  }
  &__playVideoButton {
    background-color: transparent;
    font-weight: 700;
    color: #de1919 !important;
    height: fit-content;
    user-select: none;
  }

  &__playVideoButtonMarginLeft {
    margin-left: 1em;
  }

  &__syncTime {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 8px 0;
    cursor: pointer;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__timeNameInput {
    min-width: 50%;
  }

  &__linkButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    user-select: none;
  }

  &__input {
    width: 180px;
  }

  &__fullWidth {
    align-items: center;
    display: flex;
    width: 100%;
  }

  &__centered {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width: $small_screen) {
      & .Button {
        width: 100%;
        margin: 0;
      }
    }
  }

  &__gapBetween {
    column-gap: 2rem;
  }

  &__uploadRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &__saveCancelButonsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 0.5rem;
    column-gap: 1rem;
    flex-wrap: wrap;
    > * {
      margin: 0;
    }
  }

  &__videoSetupPageButtonRow {
    .MatchButton {
      width: $input-button-width;
      flex-shrink: 0;

      @include button-mobile-full-width;
    }

    @media (max-width: $small_screen) {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: 100%;

      gap: 10px;
      & > .MatchDetailVideoContent__fileInputButton,
      & > .MatchButton,
      & > .MatchDetailVideoContent__MatchButtonWrapper {
        justify-self: end;
      }
    }
  }
}

.MatchDetailVideoContent--dark {
  .MatchDetailVideoContent__container {
    background-color: #212121;
  }

  .MatchDetailVideoContent__premium {
    opacity: 0.54;
  }
}
