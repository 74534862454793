.MatchDetail {
  width: 100%;
  background-color: #f9f9f9;
  height: 100%; // fix for browsers dont supporting env()
  height: calc(100% - env(safe-area-inset-bottom, 0)); //iOS bezel fix
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: auto;

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    height: 100%;
  }

  &__content {
    display: flex;
    position: absolute;
    height: fit-content;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 1024px) {
      padding: 10px;
    }

    @media (min-width: 1025px) {
      padding: 23px;
    }
  }

  &__buttonWrapper {
    width: 100%;
    background: #f9f9f9;
    height: 80px;
    padding-top: 20px;
    position: fixed;
    left: 0;
    bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1025px) {
      position: relative;
      bottom: 0;
      margin: 0 0 50px;
      padding-top: 0;
      background: none;
    }
  }

  &__buttonWrapper--dark {
    background: #191919;
  }

  &__buttonWrapperSingle {
    width: 100%;
    background: #f9f9f9;
    height: 80px;
    padding-top: 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1025px) {
      position: relative;
      bottom: 0;
      margin: 0 0 50px;
      padding-top: 0;
      background: none;
    }
  }

  &__buttonWrapperSingle--dark {
    background: #191919;
  }
}

.MatchDetail--dark {
  background-color: #191919;
  color: white;

  .MatchDetail__button {
    color: rgba(#fff, 0.8);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
