.PremiumPage {
  width: 100%;
  background-color: #f9f9f9;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;

  tr td span {
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #de1919;
    border-radius: 50%;
    margin: 0px 12px 3px;
  }

  tr td {
    font-size: 15px;
  }

  tr td:nth-child(1) {
    font-weight: bold;
    width: 255px;
  }

  tr td:nth-child(1)::before {
    display: inline-block;
    content: '';
    -webkit-border-radius: 0.375rem;
    border-radius: 0.375rem;
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 24px;
    background: #de1919;
  }

  &__container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
  }

  &__content {
    position: absolute;
    height: fit-content;
    width: 80%;
    padding: 20px;
    margin: 30px 0;
    border-radius: 10px;
    background: white;

    .offers {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0px 20px 0px;
    }

    .offers > * {
      margin-right: 20px;
    }

    @media (max-width: 750px) {
      .offers {
        display: block;
        margin: 0px;
      }

      .offers > * {
        margin-right: 0px;
      }
    }

    & > p {
      text-align: center;
      margin: 0px;
      font-size: 15px;
    }

    hr {
      display: block;
      margin: 24px 0px;
    }

    @media (min-width: 1025px) {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }

  @media (max-width: 1025px) {
    .MobileTitle {
      top: 0px;
      // background: #f9f9f9;
    }
  }

  &__subscribeButton {
    margin-bottom: 14px;
  }

  &__premiumModalHeader {
    display: flex;
    justify-content: center;
    font-weight: bold;
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }

  &__premiumModalContent {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    div {
      margin-top: 20px;
    }
  }

  &__loadingContainer {
    height: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.PremiumPage--dark {
  background-color: #191919;
  color: white;

  .PremiumPage__content {
    background: #212121;
  }

  @media (max-width: 1025px) {
    .MobileTitle {
      position: sticky;
      top: 0px;
      background: #191919;
      z-index: 1000;
    }
  }
}

.PremiumPage__offer {
  border: 1px solid #de1919;
  border-radius: 10px;
  position: relative;
  width: 300px;
  min-height: 85px;

  p {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  p:nth-child(2) {
    font-weight: bold;
    font-size: 17px;
  }

  p:nth-child(3) {
    top: 25px;
    font-style: italic;
    font-size: 14px;
  }

  p > span:nth-child(1) {
    position: absolute;
    left: 50px;
    width: 40%;
  }
  p > span:nth-child(2) {
    position: absolute;
    left: 60%;
  }
  p > span:nth-child(3) {
    position: absolute;
    right: 20px;
  }

  @media (max-width: 750px) {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

.PremiumPage__offer__oldPrice {
  text-decoration: line-through;
  color: #de1919;

  span {
    color: #707070;
  }
}

.PremiumPage__offer h4 {
  background: #de1919;
  color: white;
  width: 75%;
  font-weight: normal;
  text-align: center;
  border-radius: 10px;
  padding: 3px;
  box-sizing: border-box;
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.unactive_offer {
  border: 1px solid #707070;
}

.unactive_offer h4 {
  background: #707070;
}

.restoreAll {
  font-size: 15px;
  padding-bottom: 15px;
  color: #707070;
  cursor: pointer;
}
