.DigiRecorderStopwatch {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  row-gap: 15px;
  align-items: center;

  &__dot {
    --sz: min(40px, 10vw);
    width: var(--sz);
    height: var(--sz);
    border-radius: var(--sz);
    background-color: red;
    border: 3px solid rgba(187, 187, 187, 0.07);
    justify-self: end;
    transition: background-color 200ms ease;

    &--off {
      background-color: transparent;
    }
  }

  &__time {
    font-weight: bold;
    font-size: min(3rem, 10vw);
    width: 8ch;
    text-align: center;
  }

  &__label {
    grid-column: 1 / span 3;
    text-align: center;
  }

  a {
    text-decoration: underline;
    font-weight: bolder;
    color: inherit;
  }
}
