.AddCategoryContent {
  min-height: 500px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
}
