.NoMatches {
  height: 480px;

  &__wrapper {
    height: 480px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__matchtableWrapper {
    background-color: rgba(255, 255, 255, 0.7);
    position: relative;
    border-radius: 12px;
    height: 460px;
    width: calc(100% - 22px);
    top: -460px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__image {
    height: 150px;
    margin-top: 3em;
  }

  &__text {
    margin: 2em 0;
    padding: 1em;
    text-align: center;
  }

  &__button {
    padding: 0.5em 1em;
    background-color: #de1919;
    border: 2px solid #de1919;
    border-radius: 35px;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1em;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.NoMatches--dark {
  .NoMatches__matchtableWrapper {
    background-color: rgba(255, 255, 255, 0.00025);
  }
}
