.NoNotes {
  height: 480px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__image {
    height: 150px;
  }

  &__text {
    margin: 2em 0;
    padding: 1em;
    text-align: center;
  }

  &__button {
    padding: 0.5em 1em;
    background-color: #de1919;
    border: 2px solid #de1919;
    border-radius: 35px;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1em;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
