body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.PopperShadow {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.PopperShadow--dark {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.mx-1 {
    margin: 0rem 1rem;
}

@mixin shrink-padding-on-mobile {
  @media (max-width: 470px) {
    padding: 10px;
  }
}
