.App {
  width: 100%;
  height: 100vh;

  h1 {
    margin: 0;
  }
}

.App--dark {
  background: black;
  color: white;
}

* {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
}

input, textarea {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
