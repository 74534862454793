.NoNFCBanner {
  &__wrapper {
    max-width: 420px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__text {
    margin: 36px 0;
  }

  &__btnRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin: 20px 0;
    padding: 0 20px;
  }

  &__btn {
    width: 200px;
    max-width: 45%;
    object-fit: contain;
    cursor: pointer;
    border-radius: 6px;
  }
}
