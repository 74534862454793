.MobileLayout {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__middleContent {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
