.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 19px;
  left: 10px;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  border: 2px solid #eee;
}

.checkmark-active {
  border: 2px solid #de1919;
}

.container input:checked ~ .checkmark {
  background-color: #eee;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 7px;
  left: 7px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #de1919;
}
