.IsOffline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__text {
    color: #000;
    font-weight: 700;
    margin-bottom: 40px;
  }
}

.IsOffline--dark {
  .IsOffline__text {
    color: #fff;
  }
}
