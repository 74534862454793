.Notification {
  &__alertInfo {
    background-color: #191919 !important;
    color: white !important;

    &--dark {
      background-color: white !important;
      color: #191919 !important;
    }
  }
}
