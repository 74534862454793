.ClipDetailTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  height: 106px;

  @media (min-width: 1025px) {
    margin: 0 48px 0 48px;
    border-bottom: 1px solid #c5c5c5;
    justify-content: space-between;
  }

  @media (max-width: 1310px) {
    &__tabletTitleHidden {
      display: none;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button {
    &--green {
      background-color: #4caf50;
      color: white;
    }

    &--dark {
      background-color: #616161;
    }

    &--disable {
      color: #c3c3c3;
    }
  }

  &__buttonLabel {
    margin-right: 10px;
  }

  &__backButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    user-select: none;
  }
}

.ClipDetailTitle--dark {
  @media (min-width: 1025px) {
    border-bottom: 1px solid #777777;
  }

  .ClipDetailTitle__button {
    &--green {
      background-color: #4caf50;
      color: white;
    }
  }
}
