@import 'src/variables';

@mixin shrink-padding-on-mobile {
  @media (max-width: 600px) {
    padding: 15px 10px;
    width: CALC(100vw - 36px);
  }
}

@mixin mac-like-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

@mixin button-mobile-full-width {
  @media screen and (max-width: $small_screen) {
    width: calc(100vw - 125px);
  }

  @media screen and (max-width: 600px) {
    width: calc(100vw - 60px);
  }

  .Modal & {
    @media screen and (max-width: $small_screen) {
      width: calc(100vw - 100px);
    }
  }
}

@mixin button-mobile-full-width-extra-small-only {
  @media screen and (max-width: 600px) {
    width: calc(100vw - 60px);
  }
}

@mixin hide-on-mobile {
  @media screen and (max-width: $small_screen) {
    display: none;
  }
}
