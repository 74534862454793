.CompetitionDetailPage {
  width: 100%;
  background-color: #f9f9f9;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__scrollWrapper {
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__contentWrapper {
    position: absolute;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 5px 0;
    padding-bottom: 15px;

    @media (min-width: 1025px) {
      flex-direction: row;
    }
  }

  &__container {
    width: calc(100% - 80px);
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    margin: 10px 10px 0px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

    @media (min-width: 1025px) {
      width: 500px;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &__headerText {
    font-weight: 700 !important;
    font-size: 0.92rem !important;
  }
}

.CompetitionDetailPage--dark {
  background-color: #191919;
  color: white;

  .CompetitionDetailPage__container {
    background-color: #212121;
  }
}
