.VideoPlayer {
  @media (min-width: 1025px) {
    min-height: 300px;
  }

  video {
    width: 100%;
    height: 100%;
    border-radius: 0;

    @media (min-width: 1025px) {
      border-radius: 10px;
    }
  }

  video::-webkit-media-controls {
    display: none;
  }

  &__controls {
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px);
    margin: -70px 0px 0;
    z-index: 1000;
    position: relative;
    padding: 5px 10px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    @media (min-width: 1025px) {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &--visible {
      opacity: 1;
    }
  }

  &__loaderWrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    margin-top: -56.25%;
  }

  &__loader {
    height: 100%;
    width: 100%;
    position: absolute;
  }

  &__progressBar {
    -webkit-appearance: none;
    width: 100%;
    margin: 10px 0;
    height: 0.3em !important;
    background: #c8c8c8;
    cursor: pointer;
  }

  &__progressBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #de1919;
    height: 13px;
    width: 13px;
    border-radius: 10px;
    background: #de1919;
    cursor: pointer;
    margin-top: 0px;
  }

  &__progressBar:focus {
    outline: none;
  }

  &__controlBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__controlsLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__controlsRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__playerButton {
    margin-right: 5px;
  }

  &__whiteIcon {
    fill: #fff !important;
    cursor: pointer;
  }

  &__playPauseIcon {
    fill: #fff !important;
    width: 1.8em !important;
    height: 1.28em !important;
    cursor: pointer;
  }

  &__screenIcon {
    width: 1.28em !important;
    height: 1.28em !important;
    fill: #fff !important;
    margin-right: 10px;
    cursor: pointer;
  }

  &__inputVolume {
    margin-right: 15px;
    margin-left: 10px;
    cursor: pointer;
    height: 4px;
    border-radius: 10px;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
  }

  &__inputVolume::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #fff;
    height: 13px;
    width: 13px;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    margin-top: 0px;
  }

  &__menuItem {
    &:hover {
      opacity: 0.4;
    }
  }
}
