.UserAuthLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;

  &__container {
    width: calc(100% - 60px);
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 40px 10px;

    @media (min-width: 1025px) {
      width: 400px;
    }
  }

  a {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    height: fit-content;
    font-size: 16px;
    color: #777;
    display: inline-block;
  }

  .textField {
    width: 100%;
    margin: 5px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .button {
    width: 100%;
    height: 50px;
    margin: 30px 25px 0;
    background-color: #de1919;
    color: white;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &--disabled {
      background-color: #c5c5c5;
      color: black;
      cursor: not-allowed;
    }
  }
}

.UserAuthLayout--dark {
  background-color: #191919;

  .UserAuthLayout__container {
    background-color: #212121;
  }
}
