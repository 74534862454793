.ClipSubController {
  &__premium {
    width: 100%;
    margin-top: 1px;
    display: flex;
    align-items: center;
    opacity: 0.3;
    font-size: 0.7em;
    cursor: pointer;
  }

  &__premium__icon {
    margin-right: 0.3em;
    font-size: 1.5em !important;
  }
}
