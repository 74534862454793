@import '../../mixins';

.MatchDetailAccountingContent {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 1025px) {
    min-height: 100%;
  }

  &__container {
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 30px;

    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

    @include shrink-padding-on-mobile;

    @media (max-width: 600px) {
      width: auto;
    }
  }

  &__containerHeading {
    font-weight: 700;
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }

  &__sumInput {
    margin-top: 2em;
  }

  &__sumInput > * {
    font-weight: 900 !important;
  }

  &_receivedAs {
    margin-top: 62px;
  }
}

.MatchDetailAccountingContent--dark {
  .MatchDetailAccountingContent__container {
    background-color: #212121;
  }
}
