.ClipInformation {
  width: calc(100% - 80px);
  margin: 0 10px;
  padding: 30px;
  background-color: white;
  border-radius: 10px;

  @media (min-width: 1025px) {
    width: calc(100% - 70px);
    margin: 0 20px 0 0;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0.4em;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h4 {
      margin: 0;
    }
  }

  &__moreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 0.5em;
  }

  &__moreTooltip {
    width: 160px;
    position: absolute;
    padding: 10px;
    background-color: white;
    top: 10px;
    right: -1px;
    display: none;
    z-index: 9;
    border-radius: 10px;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

    &--visible {
      display: block;
    }
  }

  &__moreTooltip--dark {
    background-color: #191919;
  }

  &__tooltipButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;
  }

  &__tooltipButtonLabel {
    margin-left: 10px;
  }

  &__subInformation {
    @extend .ClipInformation;
    margin-top: 10px;

    @media (min-width: 1025px) {
      width: calc(100% - 70px);
      margin-right: 20px;
    }

    h4 {
      margin: 0;
    }

    &--dark {
      background-color: #212121;
    }
  }

  &__publicNote .MuiInputBase-root.Mui-disabled {
    color: unset;
  }

  &__ratings {
    display: block;

    &--first {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
}

.ClipInformation--dark {
  background-color: #212121;
}

.ClipController__tooltipButton--disable {
  color: #c3c3c3;
}
