.PremiumFeatureModal {
  &__textIconWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2em;

    p {
      max-width: 25em;
      margin: 0 0 1em 2em;
      line-height: 1.5em;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
