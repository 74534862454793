.NoteListPage {
  width: 100%;
  background-color: #f9f9f9;
  height: 100%;
  padding: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &__container {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &__content {
    width: 100%;
    position: absolute;
    height: 100%;
    display: flex;
  }
}

.NoteListPage--dark {
  background-color: #191919;
  color: white;
}
