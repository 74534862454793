.MatchDetailOthersTab {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 601px) and (max-width: 1024px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (min-width: 1025px) {
      min-height: 100%;
      flex-wrap: nowrap;
    }

    &--mobile {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
    }

    & > div {
      width: 100%;
      @media (min-width: 1025px) {
        width: 500px;
      }
    }
  }
}
