.InfoModal {
  &__textIconWrapper {
    display: flex;
    flex-direction: row;
    padding: 2em;

    p {
      width: 25em;
      margin: 0 0 0 2em;
      line-height: 1.5em;
    }
  }
}
