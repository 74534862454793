.MatchButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  font-size: 12px;
  text-align: center;
  border-radius: 50px;
  font-weight: 700;
  //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  width: 140px;
  height: 19px;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  .left-icon {
    padding-top: 4px;
  }

  @media (min-width: 1025px) {
    font-size: 14px;
    width: 150px;
  }

  &__primary {
    color: white;
    background-color: #de1919;
    border: 2px solid #de1919;
  }

  &__secondary {
    background-color: transparent;
    font-weight: 700;
    color: #de1919 !important;
    user-select: none;
    border: 2px solid #de1919;
  }

  &__done {
    color: white;
    background-color: #4caf50;
    border: 2px solid #4caf50;

    &:hover {
      background-color: #388e3c;
    }
  }

  &--disabled {
    background-color: #ececec;
    color: #c3c3c3 !important;
    border: 2px solid #ececec;
  }

  &--dark--disabled {
    background-color: #858585;
    color: #c3c3c3;
    border: 2px solid#858585;
  }
}
