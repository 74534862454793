.ScrollButton {
  width: 50px;
  height: 50px;
  background-color: #e3e3e3;
  border-radius: 100px;
  margin: 0 -50px 0 0;
  position: relative;
  left: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  opacity: 0.7;
}
