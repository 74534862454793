.ClipDetailPage {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;

  &__buttons {
    padding-bottom: calc(10px + env(safe-area-inset-bottom)); //iOS bezel fix
    @media (min-width: 1025px) {
      padding-bottom: calc(25px + env(safe-area-inset-bottom)); //iOS bezel fix
    }
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: auto;

    @media (min-width: 1025px) {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 1025px) {
      flex-direction: row;
      margin: 0 50px;
    }
  }

  &__moreButton {
    position: 'relative';
    display: flex;
  }

  &__contentWrapper {
    order: 1;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    height: fit-content;

    @media (min-width: 1025px) {
      margin: 0;
      order: 0;
      min-width: 390px;
      max-width: 390px;
    }
  }

  &__redirectBtnWrapper {
    display: flex;
    justify-content: center;
    padding: 30px 0;
  }

  &__redirectBtn {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #212121;
    padding: 5px 10px;

    &:hover {
      opacity: 0.7;
    }
  }

  &__edgeWarning {
    margin: 15px 10px;
    padding: 30px;
    background-color: #ff98008c;
    border-radius: 10px;
  }
}

.ClipDetailPage--dark {
  background-color: #191919;
  color: white;

  .ClipDetailPage__cancelButton:hover {
    color: rgba(white, 0.5);
  }
}
