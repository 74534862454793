@import 'src/variables';

.SaveButtonsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin: 0px 0 0 0;
  padding: 10px;
  padding-bottom: 10px;

  @media (min-width: 1025px) {
    margin: 0 24px 0 24px;
    padding: 25px;
  }
  border-top: 1px solid #c5c5c5;

  &__spinner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid white;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: spin 0.6s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  }
}

.SaveButtonsWrapper--dark {
  border-top: 1px solid #777777;
}
