.FloatingAddButton {
  position: fixed;
  bottom: calc(100px + env(safe-area-inset-bottom));
  right: 20px;
  background-color: #de1919;
  font-size: 20px;
  height: 2.5em;
  width: 2.5em;
  border-radius: 100%;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;

  &:after,
  &:before {
    content: '';
    display: block;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    height: 1.1em;
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1.1em;
  }

  @media (min-width: 1025px) {
    bottom: 60px;
    right: 60px;
  }
}
