.MatchList {
  width: 100%;
  background-color: #f9f9f9;
  height: 100%;
  flex-direction: column;
  display: flex;
  padding: 0;
  overflow: auto;

  &__addButton {
    position: absolute;
    bottom: 60px;
    right: 60px;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #de1919;
    color: #ffffff;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  &__moreButtonWrapper {
    margin: 70px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    width: 100%;
    padding-top: 10px;
    display: flex;
    position: absolute;
    flex-direction: column;
    height: fit-content;
  }

  &__scrollable {
    height: 100%;
    overflow: auto;
    display: flex;
    position: relative;
    flex-direction: column;

    @media (min-width: 1025px) {
      margin-left: 24px;
      padding-top: 18px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.MatchList--dark {
  background-color: #191919;
  color: white;
}
