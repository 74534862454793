@import 'src/variables';

.BottomMenu {
  width: 100%;
  height: $bottomMenuHeight;
  background: #ffffff;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #c5c5c5;
  z-index: 50;
  padding-bottom: env(safe-area-inset-bottom); //iOS bezel fix
}

.BottomMenu--dark {
  background-color: #191919;
  border-color: #707070;
}
