.InputWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  &__iconWrapper {
    margin-right: 25px;
  }

  &__content {
    width: 100%;
  }

  &__trashIconWrapper {
    margin-left: 10px;
    cursor: pointer;
  }
}
