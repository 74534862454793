.AboutPage {
  width: 100%;
  background-color: #f9f9f9;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    height: 100%;
  }

  &__content {
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1025px) {
      margin: 0 50px;
      width: calc(100% - 100px)
    }
  }

  &__card {
    margin: 5px;
    padding: 10px 10px;
    background: white;
    color: black;
    height: fit-content;
    border-radius: 10px;
    width: 600px;
    max-width: calc(100% - 40px);
  }

  &__cardTitle {
    font-weight: bold;
    padding: 1em 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      padding-right: 1em;
    }
  }

  &__cardItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    flex-wrap: nowrap;
  }

  &__cardItemText {
    padding: 0.5em;
    padding-left: 0;
    width: fit-content;
  }

  &__cardItemContent {
    span {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: bolder;
      padding: 0.5em 0;
      text-align: right;
    }

    a {
      color: black;
    }
  }

  &__wordBreak {
    word-break: break-all;
  }

  &__flexWrap {
    @media (max-width: 550px) {
      flex-wrap: wrap;
    }
  }

  &__storeIcons {
    display: flex !important;
    justify-content: flex-start !important;
    @media (max-width: 550px) {
      flex-wrap: wrap;
    }
  }

  &__storeIcon {
    margin: 5px 0;
    height: 40px;
    object-fit: contain;
    cursor: pointer;
    border-radius: 5px;
  }
}

.AboutPage--dark {
  background-color: #191919;
  color: white;

  .AboutPage__card {
    background-color: #212121;
    color: white;
  }

  .AboutPage__cardItemContent a {
    color: white;
  }
}
