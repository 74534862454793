.DeleteAndNextModal {
  &__wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 10px;
    &--mobile {
      grid-template-columns: auto auto;

      .DeleteAndNextModal__Button--next {
        grid-column: span 2;
        justify-self: center;
      }
    }
  }
  &__Button {
    margin-inline: 0;
  }
}
