@import '../../mixins';

.MatchDetailInfoContent {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;

  @media (min-width: 1025px) {
    min-height: 100%;
    flex-wrap: nowrap;
  }

  &__container {
    width: calc(100% - 80px);
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 30px;

    @media (min-width: 1025px) {
      width: 500px;
    }
    @include shrink-padding-on-mobile;
  }

  &__containerHeading {
    font-weight: 700;
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }

  &__datePicker,
  &__select,
  &__textFiled {
    width: 100%;
  }

  &__timePicker {
    width: 100%;
  }

  &__textFiledDivider {
    margin: 0 10px;
  }

  &__teamsWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
  }
}

.MatchDetailInfoContent--dark {
  .MatchDetailInfoContent__container {
    background-color: #212121;
  }
}
