.DigiRecorderSituations {
  &__heading_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 5px;
  }
  &__heading {
    font-weight: 700;
    font-size: 1.2em;
  }

  &__premium {
    cursor: pointer;
    color: red;
    display: flex;
    align-items: center;

    &_icon {
      margin: 0.25rem;
      font-size: 1.1rem !important; // MUI overrides otherwise
    }
    &_text {
      font-size: 1rem;
    }
  }

  &__table_head_item {
    font-weight: 700 !important; // MUI overrides otherwise
  }

  &__table_empty_row {
    text-align: center !important; // MUI overrides otherwise
  }

  &__addCategory {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    &__button {
      flex-shrink: 0;
      background: #777;
      width: 40px;
      height: 40px;
      border-radius: 999px;
      cursor: pointer;
      position: relative;
      &::after {
        content: '+';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 1px));
        font-size: 1.5rem;
        font-weight: bolder;
        line-height: 1;
      }
    }
  }

  &__categories {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
  }
  &__categoryTitle {
    font-size: 1.2rem;
    font-weight: bolder;
    margin-bottom: 5px;
  }
  &__categoryList {
    display: flex;
    flex-direction: column;
  }
  &__categoriesContainer {
    height: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 20px;
  }
}
