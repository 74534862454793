.VideoSwimLine {
  width: calc(100%);

  &__clips {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      display: none;
    }

    &--uncollapsed {
      flex-wrap: wrap;
      margin-inline: 20px;
    }
  }

  &__clip {
    flex: 0 0 auto;
    margin: 0 10px;

    img {
      width: 500px;
      height: 250px;
      object-fit: cover;
    }
  }

  &__title {
    font-weight: bold;
    cursor: pointer;

    margin-left: 16px;

    @media (min-width: 768px) {
      margin-left: 36px;
    }

    &:hover {
      color: rgba(black, 0.5);
    }
  }

  &__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    margin: 10px 0;

    @media (min-width: 1025px) {
      width: calc(100vw - 220px);
    }
  }
}

.VideoSwimLine--dark {
  .VideoSwimLine__title:hover {
    color: rgba(white, 0.5);
  }
}
